<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="inbox-fill" aria-hidden="true"></b-icon>
        <span>{{ header }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>
    <p>{{ title }}</p>
    <validation-observer ref="observer2" v-slot="{ handleSubmit, invalid }">
      <div class="mt-2">
        <base-datepicker
          vid="from"
          name="da"
          label="Da"
          v-model="form.from"
          :rules="{ required: true }"
        />
      </div>
      <div class="mt-2">
        <base-datepicker
          vid="to"
          name="a"
          label="A"
          v-model="form.to"
          :rules="{ required: true }"
        />
      </div>

      <div class="mt-2">
        <base-select
          vid="salesmen"
          label="Produttori"
          name="produttori"
          :multiple="true"
          v-model="form.broker"
          :options="salesmen"
          :taggable="salesmen_taggable"
          :closeOnSelect="false"
          :rules="{ required: true }"
          :defaultHtmlMultipleSelect="true"
          @changeTaggable="changeTaggable"
        />
        <b-button
          :pressed.sync="myToggle"
          variant="light"
          class="mb-2"
          @click="myToggle ? selectAllBrokers() : deselectAllBrokers()"
          >{{ myToggle ? "Deseleziona tutti" : "Seleziona tutti" }}</b-button
        >
      </div>

      <b-button
        @click="handleSubmit(onGenerate)"
        type="button"
        variant="outline-lisaweb"
        size="sm"
        class="mt-2 ml-4 float-right"
        :disabled="invalid"
      >
        Avvia
      </b-button>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  data() {
    return {
      myToggle: false,
      salesmen_taggable: true,
      salesmen: [],
      form: {
        from: null,
        to: null,
        broker: [],
      },
    };
  },
  props: {
    id: {
      type: String,
      default: "generateBrokerStatementModal",
    },
    value: null,
    title: String,
    header: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    BaseSelect,
    BaseDatepicker,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    onGenerate() {
      const form = JSON.parse(JSON.stringify(this.form));
      this.$emit("generate", form);
      this.close();
    },
    selectAllBrokers() {
      this.form.broker = this.getSalesmen().map((salesman) => salesman.value);
    },
    deselectAllBrokers() {
      this.form.broker = [];
    },
    close() {
      Object.keys(this.form).forEach((key) => (this.form[key] = null));
      this.myToggle = false;
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  created() {
    this.salesmen = this.getSalesmen();
  },
};
</script>
